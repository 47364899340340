<template>
  <b-row class="justify-content-center">
    <b-col md="6">
      <b-card>
        <b-form v-on:submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Name" label-for="vendorcategory">
                <b-form-input
                  id="vendorcategory"
                  placeholder="Name"
                  v-model="form.name"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                class="mr-1"
                @click="addVendorCategory()"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  components: {
    ToastificationContent,
    vSelect,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        name: "",
        selected: null,
        options: [],
      },
      errors: "",
      errMessage: "",
    };
  },
  created() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      this.$http.get("vendor/categories/all").then((response) => {
        // console.log(response.data.data)
        // this.form.options.push({value: null, text: 'Optional'})

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.form.options.push($data);
        }
        // console.log(this.form.options)
      });
    },
    addVendorCategory() {
      this.$http
        .post("vendor/category/add", {
          name: this.form.name
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success add Vendor Category",
              variant: "success",
            },
          });
          location.href = "/product/vendor-category";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>